<template>
  <div class="GrilleTarif">
    <table>
      <thead>
      <tr>
        <th colspan="1">Nombre de calendriers</th>
        <th colspan="1">Réduction</th>
        <th colspan="1">Prix unitaire HT <br> (port inclus)</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="reduc in reducts" :key="reduc">
        <td>{{ reduc.min }} calendriers</td>
        <td>{{ reduction(reduc) }}</td>
        <td>{{ prixunitHT(reduc).toFixed(2) }} €</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'etapesTarifs',
  data() {
    return {
      reducts: [],
    }
  },
  methods: {
    prixunitHT(reduc) {
      return (5.25-reduc.montant)*(1-reduc.pourcent/100)
    },
    reduction(reduc) {
      let finalVal = ""
      if (reduc.montant == 0) {
        finalVal = 'Réduction ' + reduc.pourcent + '%'
      }
      return finalVal
    }
  },
  created() {
    const api = require('../../api')
    let auth = api.getCookie('authentification')
    var requestOptions = {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + auth
      },
      redirect: 'follow'
    };
    fetch("https://www.aventbirthday-pro.fr/api/codepromo/reducPannier", requestOptions)
        .then(response => response.json())
        .then(result => this.reducts = result)
        .catch(error => console.log('error', error));
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.GrilleTarif {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

table {
  transition: all 1s ease;
  width: 50%;
  min-height: 50vh;
  background-color: $fancy;
  border-radius: 10px;
  padding: 1px;
  font-size: $fontsize;
  thead {
    background-color: $grey;
    th {
      border-radius: 5px;
      padding: 5px 4px;
    }
  }
  tbody {
    font-size: $fontsize;
    td {
      padding: 5px 4px;
      text-align: center;
      background-color: $blanchatre;
      border-radius: 3px;
    }
    .last-row {
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }
      ul {
        display: flex;
        flex-direction: column;
        text-align: left;
        list-style: none;
        font-size: $fontsize;
        li {
          padding-left: 30px;
          &::before {
            content: "- ";
          }
        }
      }
    }
    img {
      height: 200px;
    }
  }
}

@media (max-width: 1000px) {
  table {
    width: 90%;
    transition: all 1s ease;
  }
}

@media (max-width: 600px) {
  table {
    width: 100%;
    transition: all 1s ease;
    tbody {
      .last-row {
        div {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          ul {
            margin: 5px;
          }
        }
      }
    }
  }
}
</style>