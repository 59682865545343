<template>
  <div class="Composition">
    <h1>Composez votre Commande</h1>
    <div class="product-section">
      <Produit v-for="(produit, index) in Produits" :key="index" :count="produit.count" :rest="rest" :image="produit.img" :name="produit.name" @remove="remove(index)" @add="add(index)" @setVal="setVal(index,$event)" />
    </div>
    <h2>{{ howMany }}</h2>
    <div class="button">
      <button @click="Back">Retour</button>
      <button @click="Next">Suivant</button>
    </div>
  </div>
</template>

<script>
import Produit from './Produit.vue'

export default {
  props: ['items', 'total'],
  data() {
    return {
      Produits: [
        {
          id: 1,
          name: 'Maternelle',
          img: '1-maternelle.png',
          count: 0
        },
        {
          id: 2,
          name: 'CP',
          img: '2-cp.png',
          count: 0
        },
        {
          id: 3,
          name: 'CE1',
          img: '3-ce1.png',
          count: 0
        },
        {
          id: 4,
          name: 'CE2',
          img: '4-ce2.png',
          count: 0
        },
        {
          id: 5,
          name: 'CM1',
          img: '5-cm1.png',
          count: 0
        },
        {
          id: 6,
          name: 'CM2',
          img: '6-cm2.png',
          count: 0
        },
        {
          id: 7,
          name: 'College',
          img: '7-college.png',
          count: 0
        }
      ]
    }
  },
  methods: {
    remove(index) {
      this.Produits[index].count--
      this.calcRest()
    },
    add(index) {
      this.Produits[index].count++
      this.calcRest()
    },
    setVal(index, e) {
      let value = Number(e)
      this.Produits[index].count = value
      this.calcRest()
    },
    calcRest() {
      let total = this.total
      for (let i = 0; i < this.Produits.length; i++) {
        const element = this.Produits[i];
        total -= element.count
      }
      this.rest= total
      return total
    },
    Next() {
      if (this.rest == 0) {
        this.$emit('Validation', this.Produits)
      }
    },
    Back() {
      this.$emit('GoBack')
    }
  },
  computed: {
    howMany() {
      let rest = this.rest
      if (rest == 0) {
        return 'La commande est complète !'
      }
      else if (rest == 1) {
        return 'Il vous reste ' + this.rest + ' calendrier à choisir'
      }
      else
        return 'Il vous reste ' + this.rest + ' calendriers à choisir'
    },
    rest() {
      return this.calcRest()
    }
  },
  components: {
    Produit
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.Composition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  background-color: $blanchatre;
  width: 70%;
  border-radius: 30px;
  h1 {
    margin: 50px 0;
  }
  .product-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
}

.button {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: 50px auto;
  justify-content: space-evenly;
  & > button:first-child {
    background-color: $grey;
    color: $fontcolor;
    margin-right: 10px;
  }
}

@media (max-width: 800px) {
  .Composition {
    width: 95%;
  }
}
</style>